import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const MyopiePage = () => (
  <Layout>
    <Seo title="Myopie management" />
    <section className="page-header">
      <h1>Myopie management</h1>
    </section>    
    <section className="page-intro">
      <div className="page-intro-content">
        <p>Bijziendheid komt steeds vaker voor, ook bij jongeren. Wist je dat op dit moment al zeker 50% van de Europese jongeren bijziend is? De ernst van het probleem wordt bovendien ook groter. De sterkste toename vindt meestal plaats tussen de leeftijd van 6 en 17 jaar oud. In deze groep is het gevaar op bijziendheid het grootst.</p>
        <p>Een bril helpt wel opnieuw scherper te zien maar werkt niet om het risico op verergering tegen te gaan. En ze hebben hun ogen nog hun hele leven nodig. Toch? Tijd voor actie!</p>
        <Link to="/contact" className="button bg-tdb-light hover:bg-tdb-dark text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-8 lg:col-start-6 col-end-13 lg:col-end-12 row-start-1 row-end-1 z-10 h-2/4 md:h-full flex justify-end items-end">
        <StaticImage
            src="../../images/myopiemgmt_hero.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="inline-block mx-2 w-12/12 h-full min-h-full"
        />
      </div>
      <div className="section-bg-right col-start-9 lg:col-start-7 col-end-13 -top-16 -right-6 lg:right-4 h-full"></div>
    </section>

    <section className="section my-10">
      <div className="section-content-center">
        <h2 className="pb-8">
          Wat is bijziendheid?
        </h2>
        <p>De wetenschappelijke term voor bijziendheid is 'myopie'.</p>
        <p>Als je bijziend bent zie je goed dichtbij, terwijl je veraf juist wazig ziet. Dit wazige zicht komt omdat je oog in de (as)lengte zo sterk groeit dat de afbeelding waar je naar kijkt niet meer óp het netvlies afgebeeld wordt, maar ervóór. Je hebt dan een correctie met een negatieve sterkte (min-sterkte) nodig om weer scherp te zien.</p>
      </div>
    </section>

    <section className="section my-10">
      {/* <div className="section-content-left col-end-13 md:col-end-10 lg:col-end-10 xl:col-end-8"> */}
      <div className="section-content-left md:col-end-7">
        <h2 className="pb-8">
          Risico's en risicofactoren
        </h2>
        <p>Bijziendheid op zich hoeft niet gevaarlijk te zijn, echter myopie is niet altijd onschuldig. Hoewel de groei van het oog plaatsvindt tijdens de kinderleeftijd en jeugd, kunnen complicaties ontstaan op oudere leeftijd. Naarmate de bijziendheid sterker aanwezig is, maw naarmate het oog langer geworden is, stijgt het risico op complicaties op latere leeftijd. Voor meer info omtrent complicaties, verwijzen wij door naar uw oogarts.</p>
        <p>Vooral voor kinderen met een grote kans op het ontwikkelen van hoge bijziendheid is het van belang om de progressie tijdig zoveel mogelijk te managen. De exacte oorzaak van bijziendheid is nog steeds niet bekend, maar er zijn wel belangrijke factoren die een rol spelen, zoals:</p>
        <ul className="list-disc list-outside ml-4">
          <li className="my-3"><strong>Erfelijkheid;</strong> als één of beide ouders bijziend zijn, is er een grotere kans op bijziendheid voor het kind. Let ook op of netvliesloslating voorkomt in de familie.</li>
          <li className="my-3"><strong>Etniciteit;</strong> onder de Aziatische bevolking komt bijziendheid veel voor (tot wel 80%).</li>
          <li className="my-3"><strong>Leefpatroon;</strong> kinderen die weinig buiten spelen en veel tijd doorbrengen op de smartphone of tablet (dichtbijwerk) ontwikkelen vaker bijziendheid.</li>
        </ul>
      </div>
      <div className="col-start-7 col-end-13 row-start-1 row-end-1 z-10 h-full flex justify-end items-start lg:items-center">
        <StaticImage
            src="../images/myopiemgmt_4.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 md:h-1/2 lg:h-2/3"
        />
      </div>
      <div className="section-bg-right col-start-8 md:col-start-8 -top-6 md:top-10"></div>
    </section>

    <section className="section">
      <div className="section-content-right">
        <h2 className="pb-8">
        En nu? Wat kunnen we doen?
        </h2>
        <p>Helaas bestaat er geen wondermiddel tegen bijziendheid, maar er zijn gelukkig wel genoeg acties die je als ouder kunt ondernemen om kinderen hiervoor zoveel mogelijk te behoeden.</p>
        <p>Is jouw kind al bijziend? Dan zijn er verschillende 'myopie management' therapieën om uit te kiezen zoals lifestyle advies, specifieke glazen of speciale contactlenzen.</p>
        <ul className="list-disc list-outside ml-4">
          <li className="my-3"><strong>Brillen glazen.</strong><br/>Er bestaan speciale glazen die niet alleen het gezichtsvermogen corrigeren, zodat je kind zowel dichtbij als veraf goed kan zien. Ze helpen ook om de toename van de bijziendheid af te remmen. Het is een veilige en gemakkelijke behandeling, omdat deze vergelijkbaar is met het dragen van gewone brillenglazen. Deze glazen zien er net zo uit als standaard enkelvoudige brillenglazen. Het materiaal van de brillenglazen is licht, dun en ze kunnen de hele dag worden gedragen bij alle activiteiten. Wij zijn myopie-management-expert in het aanmeten van deze glazen.</li>
          <li className="my-3"><strong>Contactlenzen.</strong><br/>Er bestaan soorten contactlenzen voor myopie te managen. Dit type contactlenzen zijn speciaal ontwikkeld om bijziendheid te remmen. Als expert zijn wij gespecialiseerd in dit onderdeel en kan u ook bijgevolg voor deze oplossing bij ons terecht.</li>
          <li className="my-3"><strong>Lifestyleadvies.</strong><br/>Volg de 20-20-2 regel: richt na 20 minuten dichtbij kijken je blik voor minstens 20 seconden op een punt in de verte. Daarnaast spendeer je best tenminste 2 uur per dag buiten in daglicht.</li>
        </ul>
      </div>
      <div className="col-start-1 col-end-7 row-start-1 row-end-1 z-10 h-full flex justify-start items-center lg:items-end">
        <StaticImage
            src="../../images/myopiemgmt_3.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-1/2 lg:h-4/6"
        />
      </div>
      <div className="section-bg-left"></div>
    </section>
  </Layout>
)

export default MyopiePage
